<template>
  <sm-editable-list
    :key="renderKey"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showViewButton
    showDeleteButton
    @edit="(id) => onClickEdit(id)"
    @change-page="fillMailBoxesUpdates()"
  >
    <template #filter-top-panet>
      <sm-datatable
        caption="Синхронизация почтовых серверов"
        :headers="mailBoxesUpdatesTableHeaders"
        :items="mailBoxesUpdatesTableItems"
      />
    </template>
    <template v-slot:readButton="{ row }">
      <sm-button :disabled="row.isReaded === 'Да'" @click="setReaded(row, true)"
        >Отметить прочитанным</sm-button
      >
    </template>
  </sm-editable-list>
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// // mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import smButton from '@/components/common/buttons/SmButton.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';

export default {
  name: 'MailBoxesMessages',

  components: {
    SmEditableList,
    smButton,
    SmDatatable,
  },

  mixins: [editableList],

  data() {
    return {
      renderKey: 0,
      controllerName: 'MailBoxesMessages',
      tableCaption: 'Почта',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Почта',
        },
      ],
      tableHeaders: [
        {
          text: 'Дата получения',
          alias: 'sentDate',
          order: 'sentDate',
        },
        {
          text: 'От кого',
          alias: 'from',
          order: 'from',
        },
        {
          text: 'Заголовок',
          alias: 'subject',
          order: 'subject',
        },
        {
          text: 'Клиент',
          alias: 'customerName',
          order: 'customerName',
        },
        {
          text: 'Аккаунт',
          alias: 'accountName',
          order: 'accountName',
        },
        {
          text: 'На какую почту получено',
          alias: 'userName',
          order: 'userName',
        },
        {
          text: 'Прочитано',
          alias: 'isReaded',
          order: 'isReaded',
        },
        {
          alias: 'readButton',
        },
        { alias: 'actions' },
      ],
      mailBoxesUpdatesTableHeaders: [
        {
          text: 'Название почты',
          alias: 'name',
          order: 'name',
        },
        {
          text: 'Последняя удачная синхронизация',
          alias: 'lastSuccessDate',
          order: 'lastSuccessDate',
        },
        {
          text: 'Последняя синхронизация',
          alias: 'lastSyncDate',
          order: 'lastSyncDate',
        },
        {
          text: 'Последняя синхронизация успешна',
          alias: 'lastSyncWasSuccess',
          order: 'lastSyncWasSuccess',
        },
        {
          text: 'Ошибка синхронизации',
          alias: 'lastSyncError',
          order: 'lastSyncError',
        },
      ],
      mailBoxesUpdatesTableItems: [],
      showEmailModalForm: false,
      emailModalForm: {},
      emailModalFields: [],
    };
  },

  created() {
    this.fillMailBoxesUpdates();
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      downloadFile: 'editableList/downloadFile',
      setAsReaded: 'email/setAsReaded',
      getMailBoxesUpdates: 'email/getMailBoxesUpdates',
    }),

    async setReaded(row, isForceUpdate = false) {
      this.fillMailBoxesUpdates();
      if (row.isReaded === 'Да') return;
      const { isSucceed } = await this.setAsReaded(row.id);
      if (!isForceUpdate || !isSucceed) return;
      this.renderKey += 1;
    },

    onClickEdit(id) {
      this.setReaded({ id });
      this.onEdit('MailBoxesSingleMessage', id);
    },

    async fillMailBoxesUpdates() {
      let result;
      try {
        result = await this.getMailBoxesUpdates();
      } catch {
        result = [];
      }
      this.mailBoxesUpdatesTableItems = result.map(row => {
        return {
          ...row,
          lastSyncWasSuccess: row.lastSyncWasSuccess ? 'Да' : 'Нет',
          lastSyncError: row.lastSyncError || '-',
        }
      });
    },
  },
};
</script>
